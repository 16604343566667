@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply m-0 mx-0 my-5 text-5xl font-bold italic tracking-tighter text-orange;
  }
  h2 {
    @apply m-0 text-3xl font-bold;
  }
  h3 {
    @apply m-0 text-2xl;
  }
  h4 {
    @apply m-0 text-xl;
  }
  h5 {
    @apply m-0 text-base;
  }

  button,
  a {
    @apply cursor-pointer;
  }

  svg {
    display: inline;
  }
}

@layer components {
  /* Loading Spinner */
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;

      &:after {
        content: ' ';
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: theme(colors.orange.DEFAULT);
        margin: -4px 0 0 -4px;
      }
      &:nth-child(1) {
        animation-delay: -0.036s;
      }
      &:nth-child(1):after {
        top: 63px;
        left: 63px;
      }
      &:nth-child(2) {
        animation-delay: -0.072s;
      }
      &:nth-child(2):after {
        top: 68px;
        left: 56px;
      }
      &:nth-child(3) {
        animation-delay: -0.108s;
      }
      &:nth-child(3):after {
        top: 71px;
        left: 48px;
      }
      &:nth-child(4) {
        animation-delay: -0.144s;
      }
      &:nth-child(4):after {
        top: 72px;
        left: 40px;
      }
      &:nth-child(5) {
        animation-delay: -0.18s;
      }
      &:nth-child(5):after {
        top: 71px;
        left: 32px;
      }
      &:nth-child(6) {
        animation-delay: -0.216s;
      }
      &:nth-child(6):after {
        top: 68px;
        left: 24px;
      }
      &:nth-child(7) {
        animation-delay: -0.252s;
      }
      &:nth-child(7):after {
        top: 63px;
        left: 17px;
      }
      &:nth-child(8) {
        animation-delay: -0.288s;
      }
      &:nth-child(8):after {
        top: 56px;
        left: 12px;
      }
    }
  }

  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @layer delay-left {
    .staggered-delay-from-left {
      div {
        opacity: 0;
        animation: fadeInFromLeft 0.2s ease-in forwards;
      }
    }
  }

  @layer delay-right {
    .staggered-delay-from-right {
      div {
        opacity: 0;
        animation: fadeInFromRight 0.2s ease-in forwards;
      }
    }
  }

  .staggered-delay-from-left div:nth-child(1),
  .staggered-delay-from-right div:nth-child(1) {
    animation-delay: 0s;
  }

  .staggered-delay-from-left div:nth-child(2),
  .staggered-delay-from-right div:nth-child(2) {
    animation-delay: 0.05s;
  }

  .staggered-delay-from-left div:nth-child(3),
  .staggered-delay-from-right div:nth-child(3) {
    animation-delay: 0.1s;
  }

  .staggered-delay-from-left div:nth-child(4),
  .staggered-delay-from-right div:nth-child(4) {
    animation-delay: 0.15s;
  }

  .staggered-delay-from-left div:nth-child(5),
  .staggered-delay-from-right div:nth-child(5) {
    animation-delay: 0.2s;
  }

  .staggered-delay-from-left div:nth-child(6),
  .staggered-delay-from-right div:nth-child(6) {
    animation-delay: 0.25s;
  }

  .staggered-delay-from-left div:nth-child(7),
  .staggered-delay-from-right div:nth-child(7) {
    animation-delay: 0.3s;
  }

  .staggered-delay-from-left div:nth-child(8),
  .staggered-delay-from-right div:nth-child(8) {
    animation-delay: 0.35s;
  }

  .staggered-delay-from-left div:nth-child(9),
  .staggered-delay-from-right div:nth-child(9) {
    animation-delay: 0.4s;
  }

  .staggered-delay-from-left div:nth-child(10),
  .staggered-delay-from-right div:nth-child(10) {
    animation-delay: 0.45s;
  }

  .staggered-delay-from-left div:nth-child(11),
  .staggered-delay-from-right div:nth-child(11) {
    animation-delay: 0.5s;
  }

  .staggered-delay-from-left div:nth-child(12),
  .staggered-delay-from-right div:nth-child(12) {
    animation-delay: 0.55s;
  }

  .staggered-delay-from-left div:nth-child(13),
  .staggered-delay-from-right div:nth-child(13) {
    animation-delay: 0.6s;
  }

  .staggered-delay-from-left div:nth-child(14),
  .staggered-delay-from-right div:nth-child(14) {
    animation-delay: 0.65s;
  }

  .staggered-delay-from-left div:nth-child(15),
  .staggered-delay-from-right div:nth-child(15) {
    animation-delay: 0.7s;
  }

  .staggered-delay-from-left div:nth-child(16),
  .staggered-delay-from-right div:nth-child(16) {
    animation-delay: 0.75s;
  }

  .staggered-delay-from-left div:nth-child(17),
  .staggered-delay-from-right div:nth-child(17) {
    animation-delay: 0.8s;
  }

  .staggered-delay-from-left div:nth-child(18),
  .staggered-delay-from-right div:nth-child(18) {
    animation-delay: 0.85s;
  }

  .staggered-delay-from-left div:nth-child(19),
  .staggered-delay-from-right div:nth-child(19) {
    animation-delay: 0.9s;
  }

  .staggered-delay-from-left div:nth-child(20),
  .staggered-delay-from-right div:nth-child(20) {
    animation-delay: 0.95s;
  }

  /* Pure React Carousel on Home Page */
  .home-page-carousel .carousel {
    position: relative;
  }
  .home-page-carousel .carousel__slider .custom-slider-animation {
    transition: transform 200ms ease 0s;
  }
  .home-page-carousel .carousel-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    height: 50px;
    width: 50px;
    background-color: theme(colors.white.DEFAULT);
    opacity: 0.7;
    border: solid 1px theme(colors.grey.mid);
    border-radius: 25px;
    color: theme(colors.black.DEFAULT);
    transition: opacity 0.1s ease-in;
  }
  .home-page-carousel .carousel-navigation:hover {
    opacity: 1;
  }
  @media (max-width: 1200px) {
    .home-page-carousel .carousel-navigation {
      height: 40px;
      width: 40px;
    }
  }
  @media (max-width: 600px) {
    .home-page-carousel .carousel-navigation {
      height: 28px;
      width: 28px;
      padding: 0;
    }
  }
  .home-page-carousel .carousel__dot-group {
    position: absolute;
    bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  @media (max-width: 1200px) {
    .home-page-carousel .carousel__dot-group {
      bottom: 20px;
    }
  }
  @media (max-width: 600px) {
    .home-page-carousel .carousel__dot-group {
      width: 100%;
      padding: 10px 0;
      bottom: 0;
    }
  }
  .home-page-carousel .carousel__dot-group .carousel__dot {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 0;
    padding: 0;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.5);
    opacity: 0.8;
    background: theme(colors.white.DEFAULT);
  }
  .home-page-carousel .carousel__dot-group .carousel__dot--selected {
    background: theme(colors.orange.DEFAULT);
  }

  /* Image Gallery */
  /* Next & previous buttons */
  .image-gallery .prev,
  .image-gallery .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: theme(colors.orange.fade);
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    user-select: none;
    background-color: transparent;
    border: none;
    z-index: 7;
  }
  /* Position the "next button" to the right */
  .image-gallery .prev {
    left: 0;
  }
  .image-gallery .next {
    right: 0;
  }
  /* On hover, add a black background color with a little bit see-through */
  .image-gallery .prev:hover,
  .image-gallery .next:hover {
    color: theme(colors.orange.DEFAULT);
  }
}

@layer utilities {
  .horizontal-scroll {
    scrollbar-width: none; /* firefox */
  }

  .horizontal-scroll ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Edge */
  }

  .clickable,
  .clickableNoLine {
    @apply cursor-pointer font-semibold text-grey-dark decoration-grey-mid decoration-1 underline-offset-[0.1em] duration-500 hover:text-black hover:decoration-orange hover:decoration-3 hover:duration-200 dark:text-white dark:hover:text-grey-mid;
  }

  .clickable {
    @apply underline;
  }

  .clearfix::after {
    content: '';
    clear: both;
    display: table;
  }

  body:has(.slideInPanel[data-open='true']),
  body:has(.modal[data-open='true']) {
    overflow: hidden;
  }
}

@import '~react-image-gallery/styles/css/image-gallery.css';
@import './goal.css';

*,
*:before,
*:after {
  box-sizing: border-box;
}
*::selection {
  background-color: theme(colors.orange.DEFAULT);
  color: theme(colors.white.DEFAULT);
}

/* Remove outline for non-keyboard :focus */
*:focus:not(:focus-visible) {
  outline: none;
}

/* Optional: Customize .focus-visible */
:not(input, select):focus-visible {
  outline: theme(colors.magenta) solid 2px;
}

/* 
  Launcher is the button to open zendesk chat
  webWidget is ...
  button.needsclick is the klaviyo popup when on LHS of screen
*/
#launcher,
#webWidget,
button.needsclick {
  z-index: theme(zIndex.3) !important;
}
/* This is zendesk chat */
iframe[name='Messaging window'],
div[style*='z-index: 999999;'] {
  z-index: theme(zIndex.3) !important;
}

@media print {
  [data-print-referal-qr-mode] body > * {
    display: none;
  }
  [data-print-referal-qr-mode] body > div[data-state='open'] {
    display: initial;
    background-color: #fff;
  }
  [data-print-referal-qr-mode] body > div[data-state='open'] > * button {
    display: none;
  }
}

.card {
  border: solid 1px theme(colors.grey.mid);
}

#loyaltylion .lion-loyalty-widget {
  z-index: theme(zIndex.1);
}

[data-theme='dark'] .card {
  border: solid 1px theme(colors.orange.DEFAULT);
}

.image-gallery.fullscreen-modal {
  z-index: theme(zIndex.3);
  background: theme(colors.white.transparent);
}
.dark .image-gallery.fullscreen-modal {
  background: theme(colors.black.transparent);
}
[data-theme='dark'] .image-gallery.fullscreen-modal {
  background: theme(colors.black.transparent);
}
.image-gallery-content.fullscreen {
  background: none;
}
.image-gallery-content.fullscreen figure {
  border: solid 1px theme(colors.grey.mid);
  padding: 10px;
  background: theme(colors.grey.light);
  max-width: 80vw;
  max-height: 80vh;
}
.dark .image-gallery-content.fullscreen figure {
  border: solid 1px theme(colors.grey.dark);
  background: theme(colors.black.transparent);
}
.image-gallery-content.fullscreen figure img {
  max-height: 700px;
  max-width: 100%;
  object-fit: contain;
}

.image-gallery .image-gallery-left-nav.image-gallery-icon,
.image-gallery .image-gallery-right-nav.image-gallery-icon,
.image-gallery .image-gallery-icon.image-gallery-fullscreen-button {
  color: theme(colors.orange.DEFAULT);
  filter: drop-shadow(0 0 5px theme(colors.white.DEFAULT));
}

.image-gallery .image-gallery-left-nav.image-gallery-icon:hover,
.image-gallery .image-gallery-right-nav.image-gallery-icon:hover,
.image-gallery .image-gallery-icon.image-gallery-fullscreen-button:hover {
  color: theme(colors.orange.DEFAULT);
}

.image-gallery .image-gallery-thumbnails-container {
  max-width: min(100vw, 500px);
}

/* Hide until hover gallery forward and back buttons for desktop */
@media (min-width: 1200px) {
  .image-gallery .image-gallery-left-nav,
  .image-gallery .image-gallery-right-nav,
  .image-gallery-icon {
    visibility: hidden;
    opacity: 0;
    transition-property: all;
    transition-delay: 0.2s;
    transition-timing-function: ease;
    transition-duration: 0.15s;
  }

  .image-gallery:hover .image-gallery-left-nav,
  .image-gallery:hover .image-gallery-right-nav,
  .image-gallery:hover .image-gallery-icon {
    visibility: visible;
    opacity: 1;
  }
}

/* Yotpo review dark mode styling */
#Reviews .y-label,
#Reviews .yotpo-icon-share,
#Reviews .yotpo-user-name,
#Reviews .yotpo-user-title,
#Reviews .share,
#Reviews .based-on,
#Reviews .yotpo-product-related-field-name,
#Reviews .content-title,
#Reviews .content-review,
#Reviews .yotpo-dropdown-button span,
#Reviews .yotpo-nav-wrapper span,
#Reviews .yotpo-sum-reviews,
#Reviews .yotpo-logo-title,
#Reviews .yotpo-icon-button-text,
#Reviews .yotpo-page-element {
  font-family: theme(fontFamily.primary);
}

#Reviews .y-label,
#Reviews .yotpo-icon-share,
#Reviews .yotpo-user-name,
#Reviews .yotpo-user-title,
#Reviews .share,
#Reviews .based-on,
#Reviews .yotpo-product-related-field-name,
#Reviews .content-title,
#Reviews .content-review,
#Reviews .yotpo-dropdown-button span {
  color: theme(colors.grey.dark);
}

.dark #Reviews .y-label,
.dark #Reviews .yotpo-icon-share,
.dark #Reviews .yotpo-user-name,
.dark #Reviews .yotpo-user-title,
.dark #Reviews .share,
.dark #Reviews .based-on,
.dark #Reviews .yotpo-product-related-field-name,
.dark #Reviews .content-title,
.dark #Reviews .content-review,
.dark #Reviews .yotpo-dropdown-button span,
.dark #Reviews .yotpo-main {
  color: theme(colors.grey.mid) !important;
}

.dark #Reviews .yotpo-page-element {
  color: theme(colors.grey.mid) !important;
}

.dark #Reviews .yotpo-star-distribution-graph-score,
#Reviews .yotpo-page-element {
  color: theme(colors.grey.dark) !important;
}
.dark #Reviews .yotpo-star-distribution-graph-score,
.dark #Reviews .yotpo-icon-profile {
  background-color: theme(colors.grey.dark);
}
.dark #Reviews .yotpo-product-related-wrapper {
  background-color: theme(colors.black.DEFAULT);
}
.dark #Reviews .yotpo-product-related-field-score-bar,
.dark #Reviews .yotpo-size-bar-empty {
  background-color: theme(colors.grey.dark);
}
.dark #Reviews .yotpo-size-bar-fit {
  background-color: theme(colors.plant.offset);
}
.dark #Reviews .yotpo-rating-bar-full {
  background-color: theme(colors.grey.mid);
}
.dark #Reviews .yotpo-size-bar-offset {
  background-color: theme(colors.red);
}
.dark #Reviews .yotpo-active .yotpo-nav-wrapper span {
  color: theme(colors.grey.mid);
}
.dark #Reviews .write-review-button,
.dark #Reviews .write-question-button {
  background-color: theme(colors.grey.dark);
}
