/* GOAL PAGE */
.container1 {
  max-width: 640px;
  float: none;
  list-style: none;
  position: relative;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  background-color: #f2f2f2;
  margin-top: 20px;
  margin-bottom: 20px;
}
.dark .container1 {
  background-color: theme(colors.grey.dark);
}

.content {
  z-index: 2;
  display: none;
  text-align: left;
  overflow: hidden;
  width: 100%;
  font-size: 16px;
  line-height: 140%;
  border: 1px solid #ccc;
  top: 30px;
  left: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f2f2f2;
  background-size: 100% auto;
}
.dark .content {
  background-color: theme(colors.grey.dark);;
}

#contentMain {
  display: block;
}

.productHeading {
  text-align: left !important;
  margin-bottom: 10px;
}
.productHeading a:link {
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: theme(colors.grey.mid);;
  text-underline-offset: 0.1em;
  transition: 500ms;
  font-weight: 600;
  color: var(--colour-text-body-fade);
  cursor: pointer;
}
.productHeading a:hover {
  text-decoration-line: underline;
  text-decoration-thickness: 3px;
  text-decoration-color: theme(colors.orange.DEFAULT);
  transition: 200ms;
  color: var(--colour-text-body);
  text-underline-offset: 0.1em;
}
.dark .productHeading a:link {
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: theme(colors.grey.mid);;
  text-underline-offset: 0.1em;
  transition: 500ms;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
.dark .productHeading a:hover {
  text-decoration-line: underline;
  text-decoration-thickness: 3px;
  text-decoration-color: theme(colors.orange.DEFAULT);
  transition: 200ms;
  color: theme(colors.grey.dark);
  text-underline-offset: 0.1em;
}

#label1,
#label2,
#label3,
#label4,
#label5 {
  text-indent: -9999px;
  background: url("/images/bn-shop-by-goal-home-icons.webp") 0 0 no-repeat;
  -webkit-animation-duration: 0s !important;
  -o-animation-duration: 0s !important;
  -moz-animation-duration: 0s !important;
  animation-duration: 0s !important;
  cursor: pointer;
}

#label1:hover,
#label2:hover,
#label3:hover,
#label4:hover,
#label5:hover {
  -webkit-animation-duration: 0s !important;
  -o-animation-duration: 0s !important;
  -moz-animation-duration: 0s !important;
  animation-duration: 0s !important;
}

#label1:hover {
  background-position: 0 -150px;
}
#label2 {
  background-position: -110px 0;
}
#label2:hover {
  background-position: -110px -150px;
}
#label3 {
  background-position: -220px 0;
}
#label3:hover {
  background-position: -220px -150px;
}
#label4 {
  background-position: -330px 0;
}
#label4:hover {
  background-position: -330px -150px;
}
#label5 {
  background-position: -440px 0;
}
#label5:hover {
  background-position: -440px -150px;
}

.label {
  display: inline-block;
  width: 110px;
  height: 150px;
  text-align: center;
  padding: 0px 0px;
  margin: 2px;
  color: #000;
  font-size: 24px;
  background: #fff;
  cursor: pointer;
  position: relative;
  border: 1px solid #ccc;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.dark .label {
  background-color: theme(colors.grey.darkest);
  color: #fff;
  border: 1px solid theme(colors.grey.mid);
}

#label40 {
  width: 250px;
}

.goals {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
}

.bag {
  text-align: center;
  position: relative;
  margin-top: 3rem;
}

.option:span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.option {
  height: 120px;
  width: 150px;
  line-height: 120px;
  text-align: center;
  border: 1px solid #ccc;
}

.option:hover {
  background-color: #e79024;
}

.resetCon {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.resetCentered {
  width: 100%;
  padding: 5px;
  min-height: 34px;
  text-align: center;
}

.reset-link {
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  font-style: italic;
  margin-top: 17px;
  padding: 5px;
  width: 125px;
  color: var(--colour-text-body-fade);
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
}

.reset-link:hover {
  background: #e79024;
  color: #fff;
}

.dark .reset-link {
  color: #fff;
}

.bulk-logo {
  min-height: 150px;
  padding: 0px;
  width: 100%;
  background: url("/images/bn-shop-by-goal-with-bulk-nutrients.webp") bottom
    center no-repeat #f2f2f2;
}
.dark .bulk-logo {
  background: url("/images/bn-shop-by-goal-with-bulk-nutrients-darkmode.webp")
    bottom center no-repeat theme(colors.grey.dark);
}

@media (max-width: 600px) {
  .bulk-logo {
    min-height: 100px;
    background-size: contain;
  }
}
@media (max-width: 375px) {
  .bulk-logo {
    min-height: 85px;
  }
}

.winner {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  min-height: 325px;
}
.dark .winner {
  background-color: theme(colors.grey.darkest);
  color: #fff;
}

.winner img.product-1 {
  float: left;
}

.winner img.product-2 {
  float: left;
  width: 150px;
  height: 150px;
}

.productImage {
  float: left;
  min-height: 300px;
  width: 255px;
}

.longDescription {
  min-height: 350px;
}

.product-1 {
  font-size: 15px;
  font-family: var(--font-family-primary);
}

.product-1:first-of-type {
  margin-top: -10px;
}

.sbgoal-breadcrumb {
  color: var(--colour-text-body);
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: capitalize;
  padding: 10px 20px 0;

  font-size: 0.9rem;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height);
}
.dark .sbgoal-breadcrumb {
  color: #fff;
}
.sbgoal-breadcrumb-link {
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: theme(colors.grey.mid);
  text-underline-offset: 0.1em;
  transition: 500ms;
  font-weight: 600;
  color: var(--colour-text-body-fade);
  cursor: pointer;
}
.sbgoal-breadcrumb-link:hover {
  text-decoration-line: underline;
  text-decoration-thickness: 3px;
  text-decoration-color: theme(colors.orange.DEFAULT);
  transition: 200ms;
  color: var(--colour-text-body);
  text-underline-offset: 0.1em;
}
.dark .sbgoal-breadcrumb-link {
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: theme(colors.grey.mid);
  text-underline-offset: 0.1em;
  transition: 500ms;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
.dark .sbgoal-breadcrumb-link:hover {
  text-decoration-line: underline;
  text-decoration-thickness: 3px;
  text-decoration-color: theme(colors.orange.DEFAULT);
  transition: 200ms;
  color: var(--colour-text-body);
  text-underline-offset: 0.1em;
}

.shop-now-1,
.shop-now-2 {
  background: #e79024;
  padding: 5px 20px;
  color: #fff !important;
  text-decoration: none;
  text-transform: uppercase;
  margin: 10px;
  display: inline-block;
  font-size: 14px;
  border-radius: 50px;
  font-family: var(--font-family-primary);
  font-style: italic;
  text-align: center;
}
.shop-now-2 {
  font-size: 13px;
}

@media (max-width: 600px) {
  .productImage {
    width: 150px;
    height: 150px;
    min-height: 160px;
  }
  .longDescription {
    min-height: 160px;
  }
}

@media (max-width: 375px) {
  .productImage {
    float: none;
    width: 150px;
    height: 150px;
    min-height: 160px;
  }
  .longDescription {
    min-height: 160px;
  }
}
